import { useState } from 'preact/hooks';
import style from './style.css';
import Popup from '@components/Popup';
import ErrorPopup from '@components/ErrorPopup';
import {splitPasteData, getEventsForMatches } from '@utils/mensesmatters';

const PasteModal = ({ setAllowPastePopup, setEvents }) => {
  const [pasteData, setPasteData] = useState();
  const [errorModal, setErrorModal] = useState();

  const handleClose = () => setAllowPastePopup(false);

  return (
    <>
      <Popup
          closePopup={handleClose}
          component={<div className={style.pasteContainer}>
            <textarea
              className={style.textArea}
              value={pasteData}
              placeholder='Paste your data here'
              onChange={(e) => setPasteData(e.target.value)}
            />
            <button onClick={() => {
              if ( !pasteData || pasteData.length < 0 || !pasteData.includes(':') ) {
                setErrorModal(`Kindly ensure that the data is not empty and follows the acceptable format before submitting.`);
                return;
              }

              const splittedData = splitPasteData({ pasteData });
              const splittedDataErrors = splittedData.errors;
              if ( splittedDataErrors && splittedDataErrors?.length > 0 ) {
                setErrorModal(`There are unrecognized event types, like: ${splittedDataErrors.join(',')}, currently we only support "Hayd", "Purity" and "Other" events.`);
                return;
              }

              const matches = splittedData.data;

              // Convert each type into our event type if and only if it's hayd/other
              if ( matches && matches?.length > 0 ) {
                const currentEvents = getEventsForMatches({ matches });

                if ( currentEvents.errors && currentEvents.errors.length > 0 ) {
                  setErrorModal(currentEvents.errors.join('<br/>'))
                  return;
                }

                const events = currentEvents.events;
                if ( events && events.length > 0 ) {
                  setEvents(events);
                  handleClose();
                } else {
                  setErrorModal(`We couldn't find any matches. It looks like the data might be in a format that we're currently unable to process. Please check the data format and try again.`);
                }

              } else {
                setErrorModal(`We couldn't find any matches. It looks like the data might be in a format that we're currently unable to process. Please check the data format and try again.`);
              }
            }} className={style.addEventBtn}>Continue</button>
          </div>}
          title={'Paste data'}
        />
      { errorModal && <ErrorPopup
        message={errorModal}
        closeErrorPopup={() => setErrorModal(false)}
      />}
    </>
  );
}
 
export default PasteModal;