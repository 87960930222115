import style from './style.css';
import { useState } from 'preact/hooks';

const Dropdown = ({ data, action, selectedItem })=>{
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className={style.dropdownContainer}>
      <button className={style.item} onClick={handleClick} disabled={!data}>
        <p>{selectedItem}</p>
        <div className={style.flex}>
          <span className={style.arrow}
            style={{ transform: open ? 'rotate(-90deg)' : 'rotate(90deg)' }} />
        </div>
      </button>
      { data && open &&
        <div className={style.selectContainer}>
          { data.map( (item) => (
            <button key={`drop_down_menu_${item}_item`} onClick={() => {
              action(item);
              handleClick();
            }}>{item}</button>
          )) }
        </div>
      }
    </div>
  );
};
export default Dropdown;
