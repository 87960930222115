import { availableColours } from '@components/Event';
import { dateTimeToTS, isValidDate } from '@utils/date';

export const splitPasteData =  ({ pasteData }) => {
  // Split the text into array of strings each is one item of their type/event
  const lowerCaseData = pasteData.toLowerCase().trim();
  const data = lowerCaseData.split(/(?=hayd:|purity:|other:)/g);

  // Check types
  const allTypes = lowerCaseData.match(/\b[a-z]+\b:/g);
  const unknownTypes = allTypes?.length > 0 && allTypes.join(' ').match(/\b(?!hayd|purity|other)\w+\b/ig)

  let errors = null;
  if ( unknownTypes?.length > 0 ) {
    errors = unknownTypes;
  } else if ( !allTypes) {
    errors = ["empty type"];
  }

  return {
    data,
    errors,
  };
};

export const getEventsForMatches = ({ matches }) => {
  const currentEvents = [];
  const errors = [];

  matches.forEach((item, i) => {
    const label = item.split(':')[0];
    if ( ['hayd', 'other'].includes(label) ) {
      const line = item.split(/(hayd:|other:|\{)/);
      const lineLength = line.length;
      
      // Handling dates
      const startDate = line[lineLength - 3] ? new Date(line[lineLength - 3].split('}')[0].replaceAll('-', '/')) : null;
      const endDate = line[lineLength - 1] ? new Date(line[lineLength - 1].split('}')[0].replaceAll('-', '/')) : null;

      // Handling colour
      const colorRegex = new RegExp(`(${availableColours.join('|')})`, 'i');
      const colourMatch = item.match(colorRegex);

      let colour;
      if ( colourMatch && colourMatch?.length > 0 ) {
        colour = colourMatch[0];
      } else {
        colour = availableColours[0];
      }
      
      if ( !startDate || !endDate || !isValidDate(startDate) || !isValidDate(endDate) ) {
        errors.push(`Invalid date/s in event number ${i+1}, "${label}" event`);
        return;
      }

      currentEvents.push({
        from: dateTimeToTS(startDate),
        to: dateTimeToTS(endDate),
        colour,
        type: 'bleeding',
      });
    }
  });
  return {
    events: currentEvents,
    errors: errors?.length > 0 ? errors : null,
  };
};