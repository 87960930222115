import { useEffect, useState } from 'preact/hooks';
import { Event } from '@components/Event';
import style from './style.css';
import PasteModal from '@components/PasteModal';

const Events = ({ action, input, eventsHeight }) => {
  const [events, setEvents] = useState(input?.events || [{ from: Math.floor(new Date().setHours(0,0,0,0) / 1000), to: Math.floor(new Date().setHours(0,0,0,0) / 1000), type: 'final_bleeding', allowEndDate: true }]);
  const [allowPastePopup, setAllowPastePopup] = useState();

  const areEventsSame = (arr1, arr2) => {
    if ( !arr1?.length || !arr2?.length || arr1.length !== arr2.length) {
        return false;
    }

    return arr1.every((obj1, index) => {
        let obj2 = arr2[index];
        return obj1.to === obj2.to && obj1.from === obj2.from;
    });
  };

  useEffect(() => {
    if ( input?.events?.length > 0 && !areEventsSame(events, input?.events) ) {
      setEvents(input.events);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, input?.events]);

  const addNewEvent = () => setEvents((prev) => {
    prev.push({});
    return [...prev];
  });
  
  const sortEvents = () => setEvents((prev) => {
    return [...prev.sort((a, b) => a.from - b.from)];
  });

  useEffect(() => {
    if ( !areEventsSame(events, input?.events) ) {
      action(events);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);
  console.log('events', events);
  return (
    <div className={style.eventsContainer} style={{ height: `${eventsHeight}px` }}>
      { allowPastePopup && <PasteModal
        setAllowPastePopup={setAllowPastePopup}
        setEvents={setEvents}
      /> }
      { events && events?.length > 0 && [...events].map((event, i) => {
        return (
          <Event
            key={`event_${i}`}
            setData={(data) => setEvents((prev) => {
              prev[i] = data;
              return [...prev];
            })}
            eventNumber={i+1}
            event={event}
            allowDeleting={events.length > 1}
            onDelete={(itemNumber) => {
              console.log('delete item', itemNumber);
              const index = itemNumber - 1;
              setEvents((prev) => {
                prev.splice(index, 1);
                return [...prev];
              })
            }}
          />
        )
      })}
      <div className={style.mainBtnsContainer}>
        <div className={style.btnsContainer}>
          <button onClick={addNewEvent} className={style.addEventBtn}>Add new event</button>
          <button onClick={sortEvents} className={style.addEventBtn}>Sort</button>
        </div>
        <button onClick={() => setAllowPastePopup(true)} className={style.addEventBtn}>Paste data</button>
      </div>
    </div>
  );
}

export default Events;
